import React, { useEffect, useState } from 'react';
import { Divider, Card, Form } from 'antd';

import SelectComponent from './SelectComponent';
import InputComponent from './Input';
import DateComponent from './DateComponent';
import '../service.css';
const data = [1]

const ServiceAddForm = (props) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    },[]);
    return(
        <Form>
            
        </Form>
    )
}


export default ServiceAddForm