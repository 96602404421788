import React from 'react'
import { Input } from 'antd';
const InputComponent = (props) => {
    return (
        <div className={props.className}>
            <label htmlFor={props.label}>{props.label} :</label>
            <div className="an_input">
                <Input value={props.value} onBlur={(e) => props.checkPONumber()} onChange={(e) => props.onChangePo(e.target.value)} placeholder={props.placeholder} />
            </div>
        </div>
    )
}
export default InputComponent;